/*!
 * Start Bootstrap - Creative v3.3.7+1 (http://startbootstrap.com/template-overviews/creative)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */
html,
body {
  height: 100%;
  width: 100%;
}
body {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  /*font-family: 'Merriweather', 'Helvetica Neue', Arial, sans-serif;*/
}
hr {
  border-color: #010034;
  border-width: 3px;
  max-width: 50px;
}
hr.light {
  border-color: white;
}
a {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  color: #040150;
}
a:hover,
a:focus {
  color: #010034;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
}
p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}
.bg-primary {
  background-color: #010034;
}
.bg-logo{
  background: #010034 url(/img/bg-logo-dark.png) no-repeat 110% 75%;
  background-size: 800px auto;
}
.bg-logo-2{
  background: #010034 url(/img/bg-logo-dark.png) no-repeat -110% -75%;
  background-size: 800px auto;
  color:#fff;
}
.bg-dark {
  background-color: #222222;
  background-position: center;
  background-image: url('../img/header5.jpg');
  background-attachment: fixed;
  color: white;
}
.bg-dark-2 {
  background-color: #f1f4f6;
  background-position: center;
  padding:50px;
  color: #778b99;
}
@media screen and (max-width: 767px) {
  .bg-dark-2{
    padding:0;
  }
  .bg-dark .row .hash{
    font-size:13px;
  }
  .bg-dark-2 .elem{
    overflow:hidden;
  }
}
.text-faded {
  color: rgba(255, 255, 255, 0.3);
}
section {
  padding: 100px 0;
}
aside {
  /*padding: 50px 0;*/
}
.no-padding {
  padding: 0;
}
.nav-logo{
  height:100%;
  width:auto;
}
.navbar-brand{
  padding:0;
  line-height: 43px;
}
.navbar-default {
  background-color: #010034;
  border-color: rgba(1, 0, 52, 0.05);
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}
.navbar-default .navbar-header .navbar-brand {
  color: #010034;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
.navbar-default .navbar-header .navbar-brand:hover,
.navbar-default .navbar-header .navbar-brand:focus {
  color: #040150;
}
.navbar-default .navbar-header .navbar-toggle {
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  text-transform: uppercase;
}
.navbar-default .nav > li > a,
.navbar-default .nav > li > a:focus {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  color: #eb8721;
}
.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus:hover {
  color: #eb8721;
}
.navbar-default .nav > li.active > a,
.navbar-default .nav > li.active > a:focus {
  color: #eb8721 !important;
  background-color: transparent;
}
.navbar-default .nav > li.active > a:hover,
.navbar-default .nav > li.active > a:focus:hover {
  background-color: transparent;
}
@media (min-width: 768px) {
  .navbar-default .navbar-header .navbar-brand {
    color: rgba(255, 255, 255, 0.7);
  }
  .navbar-default .navbar-header .navbar-brand:hover,
  .navbar-default .navbar-header .navbar-brand:focus {
    color: white;
  }
  .navbar-default .nav > li > a,
  .navbar-default .nav > li > a:focus {
    color: white;
  }
  .navbar-default .nav > li > a:hover,
  .navbar-default .nav > li > a:focus:hover {
    color: #eb8721;
  }
  .navbar-default.affix {
    background-color: #010034;
    border-color: rgba(34, 34, 34, 0.05);
  }
  .navbar-default.affix .navbar-header .navbar-brand {
    color: #010034;
    font-size: 16px;
  }
  .navbar-default.affix .navbar-header .navbar-brand:hover,
  .navbar-default.affix .navbar-header .navbar-brand:focus {
    color: #040150;
  }
  .navbar-default.affix .nav > li > a,
  .navbar-default.affix .nav > li > a:focus {
    color: #ffffff;
  }
  .navbar-default.affix .nav > li > a:hover,
  .navbar-default.affix .nav > li > a:focus:hover {
    color: #eb8721;
  }
}
header {
  position: relative;
  width: 100%;
  min-height: auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-image: url('../img/header5.jpg');
  background-attachment: fixed;
  text-align: center;
  color: white;
  min-height: 100%;
  
}

.overlay{
  width:100%;
  height:100%;
  min-height: 100%;
  background:rgba(0,0,0,0.5);
  position:absolute;
}
.overlay2{
    width:100%;
  height:100%;
  min-height: 100%;
  background:rgba(0,0,0,0.5);
  padding-top:-50px;
  padding-bottom:-50px;
  padding:75px 0;
}

header .header-content {
  position: relative;
  text-align: center;
  padding: 100px 15px 100px;
  width: 100%;
}
header .header-content .header-content-inner h1 {
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 30px;
}
header .header-content .header-content-inner hr {
  margin: 30px auto;
}
header .header-content .header-content-inner p {
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  header {
    /*min-height: 100%;*/
  }
  header .header-content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0 50px;
  }
  header .header-content .header-content-inner {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  header .header-content .header-content-inner h1 {
    font-size: 50px;
  }
  header .header-content .header-content-inner p {
    font-size: 18px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
.section-heading {
  margin-top: 0;
  color:#ffffff;
}
.section-heading-dark{
  margin-top:0;
  color:#010034;
}
#services{
  background: url(/img/bg-logo-dark.png) -150% -600% no-repeat;
}
.service-box {
  max-width: 400px;
  margin: 50px auto 0;
}
@media (min-width: 992px) {
  .service-box {
    margin: 20px auto 0;
  }
}
.service-box p {
  margin-bottom: 0;
}
.portfolio-box {
  position: relative;
  display: block;
  max-width: 650px;
  margin: 0 auto;
}
.portfolio-box .portfolio-box-caption {
  color: white;
  opacity: 0;
  display: block;
  background: rgba(240, 95, 64, 0.9);
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category,
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  padding: 0 15px;
}
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
}
.portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
  font-size: 18px;
}
.portfolio-box:hover .portfolio-box-caption {
  opacity: 1;
}
.portfolio-box:focus {
  outline: none;
}
@media (min-width: 768px) {
  .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-category {
    font-size: 16px;
  }
  .portfolio-box .portfolio-box-caption .portfolio-box-caption-content .project-name {
    font-size: 22px;
  }
}
.call-to-action h2 {
  margin: 0 auto 20px;
}
.text-primary {
  color: #040150;
}
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.btn-default {
  color: #222222;
  background-color: #eee;
  border-color: white;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}
.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #222222;
  background-color: #f2f2f2;
  border-color: #ededed;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: white;
  border-color: white;
}
.btn-default .badge {
  color: white;
  background-color: #222222;
}
.btn-primary {
  color: white;
  background-color: #040150;
  border-color: #040150;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: white;
  background-color: #010034;
  border-color: #010034;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #010034;
  border-color: #010034;
}
.btn-primary .badge {
  color: #010034;
  background-color: white;
}
.btn {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
  border: none;
  /*border-radius: 300px;*/
  font-weight: 700;
  text-transform: uppercase;
}
.btn-xl {
  padding: 15px 30px;
}
::-moz-selection {
  color: white;
  text-shadow: none;
  background: #222222;
}
::selection {
  color: white;
  text-shadow: none;
  background: #222222;
}
img::selection {
  color: white;
  background: transparent;
}
img::-moz-selection {
  color: white;
  background: transparent;
}
body {
  webkit-tap-highlight-color: #222222;
}
#tfh, #amtf{
  font-size:45px;
}
.elem{
    color: #fff;
    margin: 10px;
    padding:20px;
    background: #202446;
    border-radius:5px;
}
.elem a{
  color: #616bb8 !important;
}
#lost_id{
  font-size:10px;
}
#lost_2fa{
  font-size:10px;
}
.landing{
    margin:0;
    width:100%;
    height:100%;
    padding:0;
}
.transition-overlay{
    background:#010034;
    text-align:center;
    color:#fff;
    height:100vh;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    font-size:30px;
    font-weight:300;
    /*line-height:95vh;*/
    padding-top:40vh;
    z-index:10000;
    position:relative;
}
.rounded-logo{
  border-radius:100%;
}
.payment-logo{
  width:45px;
  height:45px;
}
.payment-brand{
  display:flex;
  justify-content: center;
}
.payment-info{
  margin-top:40px
}
#circle p{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size:1.5em;
}
.pay-amount, .pay-currency{
  margin:0;
  text-align: center;
}
.pay-amount{
  font-size:3em;
  font-weight:600;
}
.pay-amount .sml{
  font-size:0.4em;
  font-weight:500;
}
.multi-text-wrap{
  justify-content: center;
}
.pay-currency{
  font-size:1.8em;
  margin-top: -15px;
  margin-bottom: 10px;
}
.paying{
  margin-top:30px;
}
.payment-brand-text{
  margin-left: 15px;
}
.payment-brand-text h1{
  font-family: 'Montserrat', sans-serif;
  font-size:20px;
  margin:0;
}
.payment-brand-text h2{
  font-family: 'Montserrat', sans-serif;
  font-size:14px;
  margin-top:0;
}
.login-square{
  border-radius: 10px;
  padding:10px;
  padding-top:40px;
  padding-bottom:0px;
  background:#ffffff;
  color:#000;
}
.login-square hr{
  margin-top:0;
}
.bb-bottom-logo{
    width: 100%;
    display: table-row;
    height: 60px;
}
.bb-bottom-logo div{
  margin-top:10px;
  margin-bottom:10px;
}
@media screen and (max-width: 767px) {
  .login-square{
    margin-top:30px;
    margin-left: 2%;
    margin-right: 2%;
  }
}
@media screen and (max-width: 992px) {
  .pay-logo{
    display:none;
  }
}
@media screen and (max-width: 767px) {
  .pay-logo{
    display:inherit;
  }
  .pay-form{
    margin-top:30px;
  }
}
.inputs-row{
  padding-top:35px;
}
.btn-outline{
  background: white;
  border: solid 2px #010035;
  padding: 10px;
  border-radius: 5px;
  font-size: 13px;
  color:#010035;
  width:180px;
}
.btn-outline:disabled{
  color:white;
}
.pay-success{
  padding-bottom:20px;
}
#hash_link{
  word-wrap: break-word;
  font-size: 15px;
}
.payment-reminder{
  color:#888;
  font-size:11px;
}
/* inputs */

/* form starting stylings ------------------------------- */
.bb-form-group 			  { 
  position:relative; 
  margin-bottom:45px; 
}
.bb-input 				{
  font-size:18px;
  padding:10px 10px 10px 5px;
  display:block;
  width:100%;
  border:none;
  border-bottom:1px solid #010035;
}
.bb-input:focus 		{ outline:none; }

/* LABEL ======================================= */
.bb-label 				 {
  color:#999; 
  font-size:14px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:5px;
  top:10px;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}

/* active state */
.bb-input:focus ~ label, .bb-input:valid ~ label 		{
  top:-20px;
  font-size:14px;
  color:#010034;
}

/* BOTTOM BARS ================================= */
.bar 	{ position:relative; display:block; width:100%; }
.bar:before, .bar:after 	{
  content:'';
  height:2px; 
  width:0;
  bottom:1px; 
  position:absolute;
  background:#010034; 
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}
.bar:before {
  left:50%;
}
.bar:after {
  right:50%; 
}

/* active state */
.bb-input:focus ~ .bar:before, .bb-input:focus ~ .bar:after {
  width:50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position:absolute;
  height:60%; 
  width:100%; 
  top:25%; 
  left:0;
  pointer-events:none;
  opacity:0.5;
}

/* active state */
.bb-input:focus ~ .highlight {
  -webkit-animation:inputHighlighter 0.3s ease;
  -moz-animation:inputHighlighter 0.3s ease;
  animation:inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
	from { background:#010034; }
  to 	{ width:0; background:transparent; }
}
@-moz-keyframes inputHighlighter {
	from { background:#010034; }
  to 	{ width:0; background:transparent; }
}
@keyframes inputHighlighter {
	from { background:#010034; }
  to 	{ width:0; background:transparent; }
}

/* ==========  Select Field Variables ========== */
/* ==========  Select Field ========== */
/* Style Select Field */
.md-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: inherit;
  font-size:15px !important;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #010035;
  border-radius:0px;
}

.mdl-selectfield {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  position: relative;
}
.mdl-selectfield:after {
  position: absolute;
  top: 1.7em;
  right: 0.5em;
  width: 0;
  height: 0;
  padding: 0;
  content: "";
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  border-top: 0.4em solid #010035;
  pointer-events: none;
}

/* end inputs */




.main-container{
  display:table-cell;
  vertical-align:middle;
}
.pay-form h1,
.pay-form h2,
.pay-form h3,
.pay-form h4,
.pay-form h5,
.pay-form h6,
.pay-form label{
    color:#555;
    margin-top:0;
    margin-bottom:23px;
  
}
.pay-logo{
  width:27px;
  margin:0;
  margin-top:1px;
  float:left;
}
.logo-name{
  float:left;
  margin:0;
  margin-left:3px;
}
.price{
  font-weight:600;
}
.payment-description{
  color:#555;
  border-radius:3px;
  padding:20px;
  padding-bottom:10px;
  padding-top:0;
}
.boldy{
  font-weight: 600;
}
.hr-1{
  max-width:100%;
  border-color:#ddd;
  border-width:1px;
  margin-bottom:10px;
}
#_2fa_input{
  display:none;
}
.pay-success i{
  font-size:150px;
  color:#030036;
}

.body-pay{
  display:table;
}

.fade-in_10s {
    -webkit-animation: FADE_IN 10s;
    -moz-animation: FADE_IN 10s;
    -o-animation: FADE_IN 10s;
    animation: FADE_IN 10s;
}
@keyframes FADE_IN {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes FADE_IN {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes FADE_IN {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes FADE_IN {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes FADE_IN {
    0% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.front-page-logo {
  color:#fff;
  display: inline-block;
  padding: 3px 20px 0px 20px;
}

.front-page-brainblocks-logo {
  width:90px; 
  display: inline-block;
  padding-top: 1px;
}

.by-brainblocks{
  color:#fff;
  font-size:10px;
  text-transform: lowercase;
  padding: 0px 4px 0px 8px;
  font-weight: 400;
}

.navbar-brainblocks{
  color:#fff;
  position:relative;
  top:93%;
  left:65px;
  width: 120px;
}

.ip-display{
  word-break: break-word;
}
